.home__container {
    row-gap: 1rem;
}

.home__content {
    grid-template-columns: 6fr 5fr;
    padding-top: 3rem;
    column-gap: 2rem;
    align-items: center;
}

.career__content {
    grid-template-columns: 8fr 5fr;
    padding-top: 3rem;
    column-gap: 2rem;
}

.home__sns {
    max-width: 140px;
    display: flex;
    justify-content: space-between;
}

.home__sns-icon {
    font-size: 1.5rem;
    color: var(--title-color);
}

.home__sns-icon:hover {
    color: var(--title-color-dark);
}

.home__title {
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-1-5);
}

.name__ja {
    font-size: 3rem;
}

.home__subtitle {
    position: relative;
    font-size: var(--h3-font-size);
    /* padding-left: 5.4rem; */
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
}

/* .home__subtitle::before {
    content: '';
    position: absolute;
    width: 70px;
    height: 1px;
    background-color: var(--text-color);
    left: 0;
    top: 1rem;
} */

.home__description {
    max-width: 500px;
    margin-bottom: var(--mb-1-5);
    font-size: var(--normal-font-size);
}


.home__img {
    background: url(../../assets/child_mattya.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /* box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%); */
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    /* border-radius: 50% 50% 50% 50%/50% 50% 50% 50%; */
    /* animation: profile__animate 16s ease-in-out infinite 1s; */
}

.x__icon {
    width: 1.1rem;
    height: auto;
    margin-top: 0.53rem;
}

@keyframes profile__animate {
    0% {
        /* border-radius: 60% 40% 30% 70%/60% 30% 70% 40%; */
        border-radius: 50% 50% 50% 50%/50% 50% 50% 50%;
    }
    50% {
        /* border-radius: 30% 60% 70% 40%/50% 60% 30% 60%; */
        border-radius: 10% 10% 10% 10%/10% 10% 10% 10%;
    }
    100% {
        /* border-radius: 60% 40% 30% 70%/60% 30% 70% 40%; */
        border-radius: 50% 50% 50% 50%/50% 50% 50% 50%;
    }
}

.home__scroll {
    margin-left: 9.25rem;
}

.wheel {
    animation: scroll 2s ease infinite;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(0.375rem);
    }
}

.home__scroll-name {
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
    margin-left: 2px;
}

.home__scroll-arrow {
    font-size: 1.25rem;
    color: var(--title-color);
}

/*=================== BREAKPOINTS =================*/
/* For large devices */
@media screen and (max-width: 992px) {
    .home__content {
        grid-template-columns: 6fr 4fr;
        column-gap: 1.25rem;
    }

    .home__title {
        font-size: var(--h1-font-size);
        margin-bottom: var(--mb-1-5);
    }

    .name__ja {
        font-size: 2rem;
    }

    .home__subtitle {
        padding-left: 3.75rem;
        margin-bottom: var(--mb-1);
    }

    .home__subtitle::before {
        width: 42px;
        top: 0.8rem;
    }

    .home__description {
        max-width: initial;
        margin-bottom: var(--mb-2-5);
    }

    .home__img {
        width: 250px;
        height: 250px;
    }
    
    .home__scroll {
        margin-left: 7.5rem;
    }

    .home__sns {
        max-width: 150px;
    }
}
  
  
/* For medium devices */
@media screen and (max-width: 768px) {
    .home__content {
        grid-template-columns: 6fr 4fr;
        column-gap: 0.5rem;
        /* padding-top: 3.5rem; */
    }

    .home__title {
        font-size: var(--h2-font-size);
        margin-bottom: var(--mb-0-5);
    }

    .name__ja {
        font-size: 1.2rem;
    }

    .home__subtitle {
        padding-left: 3.75rem;
        margin-bottom: var(--mb-1);
    }

    .home__description {
        max-width: 100%;
        margin-bottom: var(--mb-0-5);
        font-size: var(--small-font-size);
    }

    /* .career__content {
        grid-template-columns: 1fr;
        padding-top: 3.5rem;
    } */

    /* .home__data {
        grid-column: 1/3;
    } */

    .home__img {
        width: 150px;
        height: 150px;
    }

    .home__scroll {
        display: none;
    }

    .career__content {
        grid-template-columns: 1fr;
        padding-top: 3rem;
        column-gap: 1rem;
    }

    .home__sns {
        max-width: 100px;
    }
}

/* For small devices */
@media  screen and (max-width: 350px) {
    .home__content {
        grid-template-columns: 7fr 3fr;
    }

    .career__content {
        grid-template-columns: 1fr;
    }

    .home__img {
        width: 100px;
        height: 100px;
    }
}