.works__container {
    row-gap: 1rem;
    margin-bottom: var(--mb-3);
}

.works__content {
    grid-template-columns: repeat(3, 1fr);
    padding-top: 3rem;
    column-gap: 2rem;
    align-items: center;
    place-items: center;
}

.work__item {
    margin-bottom: var(--mb-2-5)
}

.work__title {
    padding-left: 0rem;
    padding-right: 1rem;
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-5);
    color: var(--title-color);
}

.work__subtitle {
    padding-left: 0rem;
    padding-right: 1rem;
    font-size: var(--small-font-size);
    color: var(--text-color);
}

.onawa__img {
    background: url(../../assets/onawa/onawa_icon.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    /* border-radius: 10% 10% 10% 10%/10% 10% 10% 10%; */
    margin-bottom: var(--mb-0-75);
}

.papersing__img {
    background: url(../../assets/papersing/papersing_icon.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    /* border-radius: 10% 10% 10% 10%/10% 10% 10% 10%; */
    margin-bottom: var(--mb-0-75);
}

.perport__img {
    background: url(../../assets/perport/perport_icon.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    /* border-radius: 10% 10% 10% 10%/10% 10% 10% 10%; */
    margin-bottom: var(--mb-0-75);
}

.mistyfire__img {
    background: url(../../assets/mistyfire/mistyfire_icon.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    /* border-radius: 10% 10% 10% 10%/10% 10% 10% 10%; */
    margin-bottom: var(--mb-0-75);
}

.tomato__img {
    background: url(../../assets/tomato/tomato_hand_icon.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    order: 1;
    justify-self: center;
    width: 300px;
    height: 300px;
    /* border-radius: 10% 10% 10% 10%/10% 10% 10% 10%; */
    margin-bottom: var(--mb-0-75);
}

.angle-down {
    display: flex;
    justify-content: center; /* 水平方向の中央揃え */
    align-items: center;     /* 垂直方向の中央揃え */
}

.angle-down i {
    font-size: 70px; /* アイコンのサイズを24pxに設定 */
    color: var(--text-color);
}

/*=================== BREAKPOINTS =================*/
/* For large devices */
@media screen and (max-width: 992px) {
    .works__content {
        grid-template-columns: repeat(2, 1fr);
        padding-top: 3.5rem;
    }

    .work__img {
        order: initial;
        justify-self: initial;
        width: 250px;
        height: 250px;
    }

    .work__title {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        font-size: var(--normal-font-size);
        font-weight: var(--font-medium);
    }

    .work__subtitle {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        font-size: var(--small-font-size);
    }
}
  
  
/* For medium devices */
@media screen and (max-width: 768px) {
    .works__container {
        row-gap: 1rem;
        margin-bottom: var(--mb-0);
    }

    .works__content {
        grid-template-columns: 1fr;
        padding-top: 3.5rem;
    }

    .work__img {
        order: initial;
        justify-self: initial;
        width: 250px;
        height: 250px;
    }

    .work__title {
        padding-left: 0rem;
        font-size: var(--normal-font-size);
        font-weight: var(--font-medium);
    }

    .work__subtitle {
        padding-left: 0rem;
        font-size: var(--small-font-size);
    }
}

/* For small devices */
@media  screen and (max-width: 350px) {
    .work__img {
        width: 180px;
        height: 180px;
    }
}