.workdetail__container {
    row-gap: 1rem;
    margin-bottom: var(--mb-3);
}

.workdetail__img {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: var(--mb-1-5);
}

.workdetail__img__short {
    width: 70%;
    height: auto;
    display: block;
    margin: 0 auto;
    margin-bottom: var(--mb-1-5);
}

.workdetail__2grid {
    grid-template-columns: repeat(2, 1fr);
    padding-top: var(--mb-1-5);
    column-gap: var(--mb-1-5);
    align-items: center;
    place-items: center;
}

.youtube_wrap{
    padding-top: 56.25%;
    width: 100%;
    position: relative;
    margin-bottom: var(--mb-3);
}
 
.youtube_wrap iframe{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
}


.workdetail__title {
    padding-left: 0rem;
    font-size: var(--h1-font-size);
    font-weight: var(--font-medium);
    margin-top: var(--mb-2);
    margin-bottom: var(--mb-2);
    color: var(--title-color);
}

.workdetail__credit {
    padding-left: 0rem;
    font-size: var(--normal-font-size);
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1-5);
    color: var(--text-color);
}

.workdetailitem__title {
    padding-left: 0rem;
    padding-right: 1rem;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-5);
    color: var(--title-color);
}

.perport__title {
    padding-left: 0rem;
    padding-right: 1rem;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-top: 5rem;
    margin-bottom: var(--mb-1);
    color: var(--title-color);
}

.workdetail__caption {
    padding-left: 0rem;
    font-size: var(--normal-font-size);
    font-weight: var(--font-normal);
    margin-top: var(--mb-3);
    margin-bottom: var(--mb-3);
    color: var(--text-color);
}

.workdetail__weight {
    font-weight: var(--font-medium);
    color: var(--title-color);
}

@media screen and (max-width: 768px) {
    .workdetail__2grid {
        grid-template-columns: 1fr;
    }
}