.career__title {
    font-size: var(--h2-font-size);
    margin-bottom: var(--mb-0-75);
}

.organization__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.organization__subtitle {
    font-size: var(--small-font-size);
}

.organization__calendar {
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-1-5);
}

@media screen and (max-width: 768px) {
    .career__title {
        font-size: var(--h2-font-size);
    }

    .organization__title {
        font-size: var(--small-font-size);
    }

    .organization__subtitle {
        font-size: var(--smaller-font-size);
    }

    .organization__calendar {
        font-size: var(--smaller-font-size);
    }
}
